export const MOBILE_LANG = 'MOBILE_LANG'
export const MOBILE_ACCESS_TOKEN = 'MOBILE_ACCESS_TOKEN'
export const MOBILE_REFRESH_TOKEN = 'MOBILE_REFRESH_TOKEN'
export const MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE = 'MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE'
export const MOBILE_ROUTE_PARAMS = 'MOBILE_ROUTE_PARAMS'
export const MOBILE_ROUTE_PARAMS_OBSERVABLE = 'MOBILE_ROUTE_PARAMS_OBSERVABLE'
export const MOBILE_SAFE_AREA_INSETS = 'MOBILE_SAFE_AREA_INSETS'
export const MOBILE_THEME = 'MOBILE_THEME'
export const MOBILE_THEME_OBSERVABLE = 'MOBILE_THEME_OBSERVABLE'
export const MOBILE_BOTTOM_TAB_HEIGHT = 56
export const EVENT_HOUSE_SESSION_ID = 'EVENT_HOUSE_SESSION_ID'
export const CONTACTS_PERMISSION = 'CONTACTS_PERMISSION'
export const CONTACTS_UPDATE = 'CONTACTS_UPDATE'
export const CONTACTS_REQUEST = 'CONTACTS_REQUEST'
export const APP_VERSION = 'APP_VERSION'
export const APP_BUILD_VERSION = 'APP_BUILD_VERSION'

export const DEFAULT_LANG = 'kk'
export const DEFAULT_THEME = 'light'
export const DEFAULT_INSETS = '{top: 0, bottom: 0}'

export const HTTP_PARAM_SHOW_LOADER = 'show_loader'
export const HTTP_PARAM_SKIP_ERROR_HANDLE = 'skip_error_handle'
export const HTTP_PARAM_FETCHING_MEDIA = 'fetching_media'

export const HTTP_OPTIONS_SHOW_LOADER = {params: {[HTTP_PARAM_SHOW_LOADER]: true}}

export const SUPPORT_CATEGORY_LESSON_THEORY = 1
export const SUPPORT_CATEGORY_CLASSWORK = 2
export const SUPPORT_CATEGORY_TRAINER = 3
export const SUPPORT_CATEGORY_WEEKLY_TEST = 4
export const SUPPORT_CATEGORY_SCREENING_TEST = 5
export const SUPPORT_CATEGORY_OFFLINE_TRIAL_TEST = 6
export const SUPPORT_CATEGORY_CUSTOM_TEST = 7
