import {NgModule} from '@angular/core'

import {InsetsDirective} from '@ui/directives/wv/insets/insets.directive'

@NgModule({
  declarations: [
    InsetsDirective,
  ],
  exports: [
    InsetsDirective,
  ],
})
export class InsetsDirectiveModule {
}
